import { createApp } from 'vue'
import { Layout, Steps, Row, Col } from 'ant-design-vue'

import App from './App.vue'
import 'ant-design-vue/dist/antd.css'

const app = createApp(App)

;[
    // in nodejs a new object can not following a new line
    Layout,
    Row,
    Col,
    Steps,
].forEach((plugin) => app.use(plugin))

app.mount('#app')

import 'highlight.js/styles/stackoverflow-light.css'
import hljs from 'highlight.js/lib/core'
import python from 'highlight.js/lib/languages/python'
import matlab from 'highlight.js/lib/languages/matlab'
hljs.registerLanguage('python', python)
hljs.registerLanguage('matlab', matlab)
