<template>
    <div id="progress" v-if="!loading.done">
        <a-steps :current="loading.current_step">
            <a-step title="Fetch Data" />
            <a-step title="Installing Pyodide" />
            <a-step title="Installing Mat2py" />
            <a-step title="Preparing Terminal" />
            <a-step title="Almost Done" />
        </a-steps>
        <div class="steps-content">
            {{ loading.current_step_message }}
            <div v-if="loading.current_step == 0">
                <a-row
                    v-for="pkg in loading.packages"
                    :key="pkg.name"
                    v-show="pkg.download_percentage < 100"
                >
                    <a-col :span="5">{{ pkg.name }}</a-col>
                    <a-col :span="15"
                        ><a-progress
                            :stroke-color="{
                                from: '#108ee9',
                                to: '#87d068',
                            }"
                            :percent="pkg.download_percentage"
                            status="active"
                    /></a-col>
                </a-row>
            </div>
        </div>
    </div>
    <a-row>
        <a-col :span="show_internal || !!figure_data ? 15 : 24"
            ><div id="console"></div
        ></a-col>
        <a-col :span="9" v-if="!!figure_data"><img :src="figure_data" /></a-col>
        <a-col :span="9" v-if="show_internal"
            ><div id="command">
                <a-row>
                    <div>
                        <highlightjs
                            class="matlab_code"
                            language="matlab"
                            :code="src_matlab"
                        />
                    </div>
                </a-row>
                <a-row>
                    <div>
                        <highlightjs
                            class="python_code"
                            language="python"
                            :code="src_python"
                        />
                    </div>
                </a-row></div
        ></a-col>
    </a-row>
</template>

<script>
'use strict'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import { pkgs_size } from './pkgs_size.js'
import { Progress, message } from 'ant-design-vue'
import $ from 'jquery'

const PYODIDE_BASE =
    process.env.NODE_ENV === 'production'
        ? 'https://cdn.mat2py.org'
        : 'http://localhost:8080'

const Pyodide_Index_URL = `${PYODIDE_BASE}/pyodide/v0.19.1/full/`
const Pyodide_Init_Code = `
        import sys
        from pyodide import to_js
        from pyodide.console import PyodideConsole
        from pyodide.console import repr_shorten as _repr_shorten
        from mh_python import process_one_block as _translate_command
        import __main__
        BANNER = "Welcome to the Mat2Py terminal emulator\\n"
        pyconsole = PyodideConsole(__main__.__dict__)
        import builtins
        def save_command(src):
          from mat2py.core._internal.helper import CodeContext
          CodeContext()(src)
        def translate_command(src):
          try:
            return {'status': 0, 'msg': _translate_command(src)}
          except EOFError as e:
            return {'status': 1, 'msg': ''}
          except SyntaxError as e:
            return {'status': 2, 'msg': str(e)}
          
        def repr_shorten(*args, **kargs):
          return _repr_shorten(*args, **kargs).replace(']]', ']&#93;')
        async def await_fut(fut):
          res = await fut
          if res is not None:
            builtins._ = res
          return to_js([res], depth=1)
        def clear_console():
          pyconsole.buffer = []
        `

const Pyodide_Plot_Code = `
def plot(*args):
  from mat2py.common.backends import numpy as np
  from mat2py.core import plot as _plot
  from io import BytesIO
  from base64 import b64encode
  fig = _plot(*args)
  
  with BytesIO() as fp:
    fig.savefig(fp, format='png')
    return 'data:image/png;base64,' + b64encode(fp.getvalue()).decode('UTF-8')
`

function sleep(s) {
    return new Promise((resolve) => setTimeout(resolve, s))
}
function niceBytes(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let l = 0,
        n = parseInt(x, 10) || 0
    while (n >= 1024 && ++l) {
        n = n / 1024
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
}

export default {
    name: 'Console',
    data: () => ({
        loading: {
            packages: pkgs_size,
            current_step: 0,
            current_step_message: '',
            done: false,
        },
        show_internal: false,
        figure_data: '',
        src_matlab: '',
        src_python: '',
    }),
    watch: {},
    methods: {
        pre_fetch: async function (request_data) {
            try {
                // Step 1: start the fetch and obtain a reader
                let response = await fetch(
                    Pyodide_Index_URL + request_data.name
                )

                const reader = response.body.getReader()

                // Step 2: get total length
                if (response.headers.get('Content-Length')) {
                    request_data.content_length = parseInt(
                        response.headers.get('Content-Length'),
                        10
                    )
                }
                if (request_data.content_length > 1024 * 512) {
                    message.info(
                        `Start fetching ${request_data.name}(${niceBytes(
                            request_data.content_length
                        )})`
                    )
                }

                // Step 3: read the data
                let chunk = await reader.read()
                let downloaded = 0
                // let chunks = [] // array of received binary chunks (comprises the body)

                request_data.download_percentage = 0
                while (!chunk.done) {
                    downloaded += chunk.value.length
                    // chunks.push(chunk.value)
                    request_data.download_percentage = Math.round(
                        (downloaded * 100) / request_data.content_length
                    )
                    chunk = await reader.read()
                }

                // // Step 4: concatenate chunks into single Uint8Array
                // let chunksAll = new Uint8Array(downloaded) // (4.1)
                // let position = 0
                // for (let chunk of chunks) {
                //     chunksAll.set(chunk, position) // (4.2)
                //     position += chunk.length
                // }

                // // Step 5: decode into a string
                // let result = new TextDecoder('utf-8').decode(chunksAll)

                // // We're done!
                // let commits = JSON.parse(result)
                // return commits
            } catch (e) {
                message.error(
                    `${request_data.name} fetch error: ${e.message}`,
                    5
                )
                request_data.download_percentage = 0
            }
        },

        py_interpreter: async function (command, do_not_echo) {
            let ps1 = '>>> ',
                ps2 = '... '

            this.$save_command(command)

            let term = this.$term
            async function lock() {
                let resolve
                let ready = term.ready
                term.ready = new Promise((res) => (resolve = res))
                await ready
                return resolve
            }

            let repr_shorten = this.$repr_shorten
            let await_fut = this.$await_fut
            let unlock = await lock()
            term.pause()

            let fut = this.$pyconsole.push(command + '\n')
            term.set_prompt(fut.syntax_check === 'incomplete' ? ps2 : ps1)
            let wrapped = await_fut(fut)
            // complete case, get result / error and print it.
            try {
                let [value] = await wrapped
                if (value !== undefined && !do_not_echo) {
                    if (
                        typeof value === 'string' &&
                        value.startsWith('data:image/png;base64,')
                    ) {
                        this.figure_data = value
                    } else {
                        term.echo(
                            repr_shorten.callKwargs(value, {
                                separator:
                                    '\n[[;orange;]<long output truncated>]\n',
                            })
                        )
                    }
                }
                if (this.$pyodide.isPyProxy(value)) {
                    value.destroy()
                }
            } catch (e) {
                if (e.constructor.name === 'PythonError') {
                    const message = fut.formatted_error || e.message
                    term.error(message.trimEnd())
                } else {
                    throw e
                }
            } finally {
                fut.destroy()
                wrapped.destroy()
            }
            term.resume()
            await sleep(10)
            unlock()
        },

        interpreter: async function (command) {
            let ps1 = '>>> ',
                ps2 = '... '

            if (command.trimEnd() === '') {
                return
            }

            this.src_matlab = this.src_matlab + '\n' + command

            let result_py = this.$translate_command(this.src_matlab)

            let result_js = result_py.toJs()
            result_py.destroy()

            this.src_python = result_js.get('msg')
            if (result_js.get('status') === 1) {
                this.$term.set_prompt(ps2)
                return
            }
            this.$term.set_prompt(ps1)
            if (result_js.get('status') === 2) {
                this.src_matlab = ''
                this.$term.echo('[[;red;]' + result_js.get('msg') + '\n')
                return
            }
            const do_not_echo = this.src_matlab.trimEnd().endsWith(';')
            this.src_matlab = ''

            for (const command of this.src_python.split(/\n(?![\n ])/)) {
                await this.py_interpreter(command, do_not_echo)
            }
        },

        initPyodide: async function () {
            // step 1: fetch data
            this.loading.current_step = 0
            this.loading.current_step_message =
                'Setting up terminal can take quite long time, please be patient to wait...'
            await Promise.all(this.loading.packages.map(this.pre_fetch))
            if (
                this.loading.packages.some((e) => e.download_percentage === 0)
            ) {
                message.warning(
                    'Some packages are not available, you would better check the internet connection or refresh the page.',
                    15
                )
                return
            }

            // step 2: installing pyodide
            ++this.loading.current_step
            this.loading.current_step_message =
                'Installing pyodide base environment...'

            let pyodide = await window.loadPyodide({
                indexURL: Pyodide_Index_URL,
            })

            for (const pkg of ['numpy', 'scipy', 'matplotlib', 'micropip']) {
                this.loading.current_step_message = `Installing ${pkg}...`
                await pyodide.loadPackage(pkg)
            }

            // step 3: installing mat2py
            ++this.loading.current_step
            this.loading.current_step_message = 'Installing mat2py...'
            await pyodide.runPythonAsync('import micropip')

            for (const pkg of [
                'miss_hit_core',
                'mh_python',
                'mat2py==0.0.21',
            ]) {
                const url =
                    process.env.NODE_ENV === 'production'
                        ? pkg.split('-')[0]
                        : `${Pyodide_Index_URL}${pkg}`
                this.loading.current_step_message = `Installing ${url}...`
                await pyodide.runPythonAsync(
                    `await micropip.install(['${url}'])`
                )
            }

            // step 4: preparing terminal
            ++this.loading.current_step
            this.loading.current_step_message = `Preparing terminal...`
            let namespace = pyodide.globals.get('dict')()

            await pyodide.runPython(Pyodide_Init_Code, namespace)

            let repr_shorten = namespace.get('repr_shorten')
            let banner = namespace.get('BANNER')
            let await_fut = namespace.get('await_fut')
            let pyconsole = namespace.get('pyconsole')
            let translate_command = namespace.get('translate_command')
            let save_command = namespace.get('save_command')
            let clear_console = namespace.get('clear_console')
            namespace.destroy()

            pyconsole.push('from mat2py.core import *\n\n')
            pyconsole.push('def disp(*args): pass\n\n')
            pyconsole.push('from mat2py.core import plot as _plot\n\n')
            pyconsole.push(Pyodide_Plot_Code)

            this.$pyconsole = pyconsole
            this.$repr_shorten = repr_shorten
            this.$banner = banner
            this.$await_fut = await_fut
            this.$translate_command = translate_command
            this.$save_command = save_command
            this.$clear_console = clear_console
            this.$pyodide = pyodide
            if (process.env.NODE_ENV !== 'production') {
                window.pyconsole = pyconsole
                window.translate_command = translate_command
            }
        },
        keyboardInterrupt: async function () {
            this.$clear_console()
            let term = this.$term
            let ps1 = '>>> '
            term.echo_command()
            term.echo('KeyboardInterrupt')
            term.set_command('')
            term.set_prompt(ps1)
            this.src_matlab = ''
        },
        initTerm: async function () {
            let ps1 = '>>> '
            await this.initPyodide()

            // step 5: almost done
            ++this.loading.current_step
            this.loading.current_step_message = 'Just wait a bit more...'

            let pyconsole = this.$pyconsole
            let pyodide = this.$pyodide

            let term = $('#console').terminal(this.interpreter, {
                greetings: this.$banner,
                prompt: ps1,
                completionEscape: false,
                completion: function (command, callback) {
                    callback(pyconsole.complete(command).toJs()[0])
                },
                keymap: {
                    'CTRL+C': this.keyboardInterrupt,
                },
            })
            this.$term = term
            pyconsole.stdout_callback = (s) => term.echo(s, { newline: false })
            pyconsole.stderr_callback = (s) => {
                term.error(s.trimEnd())
            }
            term.ready = Promise.resolve()
            this.loading.done = true
            console.log(
                'Terminal ready. Try input matlab command: [1; 2; 3]*[1 2 3]'
            )
            pyodide._module.on_fatal = async (e) => {
                term.error(
                    'Pyodide has suffered a fatal error. Please report this to the Pyodide maintainers.'
                )
                term.error('The cause of the fatal error was:')
                term.error(e)
                term.error('Look in the browser console for more details.')
                await term.ready
                term.pause()
                await sleep(15)
                term.pause()
            }
        },
    },
    mounted: async function () {
        await this.initTerm()
    },
    components: {
        highlightjs: hljsVuePlugin.component,
        AProgress: Progress,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.terminal {
    --size: 1.5;
    --color: rgba(255, 255, 255, 0.8);
}

#console {
    text-align: left;
    height: 100%;
}

.matlab_code,
.python_code {
    /* overflow: auto; */
    width: 100%;
    height: 100%;
    /* resize: none; */
    /* box-sizing: border-box; */
    /* padding: 0 20px; */
    text-align: left;
    /* border: none; */
    /* border-right: 1px solid #ccc; */
    /* outline: none; */
    /* background-color: #f6f6f6; */
    /* font-size: 14px; */
    font-family: 'Monaco', courier, monospace;
    /* padding: 20px; */
}
</style>
