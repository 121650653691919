<template>
    <h1>
        Matlab Emulator powered by <a href="https://mat2py.org" target="_blank">Mat2Py</a>
        <img alt="MAT2PY logo" src="./assets/logo.svg" height="50" width="50" />
    </h1>
    <Console />
    <!-- <a-layout>
        <a-layout-content><Console /></a-layout-content>
        <a-layout-footer></a-layout-footer>
    </a-layout> -->
</template>

<script>
import Console from './components/Console.vue'

export default {
    name: 'App',
    components: {
        Console,
    },
}
</script>

<style>
body {
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 6px;
}

.h1 {
    float: center;
}

.h1 img {
    width: 80px;
    height: 80px;
    margin: 0px;
}

.h1 a {
    line-height: 80px;
    font-size: 30px;
    font-family: David;
    text-align: center;
}
</style>
