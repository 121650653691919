export let pkgs_size = [
    { name: 'CLAPACK.data', content_length: 2631835, download_percentage: 0 },
    { name: 'CLAPACK.js', content_length: 32635, download_percentage: 0 },
    { name: 'cycler.data', content_length: 12958, download_percentage: 0 },
    { name: 'cycler.js', content_length: 5008, download_percentage: 0 },
    { name: 'distutils.data', content_length: 516002, download_percentage: 0 },
    { name: 'distutils.js', content_length: 19510, download_percentage: 0 },
    { name: 'kiwisolver.data', content_length: 66458, download_percentage: 0 },
    { name: 'kiwisolver.js', content_length: 5644, download_percentage: 0 },
    {
        name: 'matplotlib.data',
        content_length: 11512189,
        download_percentage: 0,
    },
    { name: 'matplotlib.js', content_length: 177675, download_percentage: 0 },
    { name: 'micropip.data', content_length: 28901, download_percentage: 0 },
    { name: 'micropip.js', content_length: 6856, download_percentage: 0 },
    { name: 'numpy.data', content_length: 7422513, download_percentage: 0 },
    { name: 'numpy.js', content_length: 145038, download_percentage: 0 },
    { name: 'packages.json', content_length: 15987, download_percentage: 0 },
    { name: 'packaging.data', content_length: 68333, download_percentage: 0 },
    { name: 'packaging.js', content_length: 6884, download_percentage: 0 },
    { name: 'pillow.data', content_length: 1601756, download_percentage: 0 },
    { name: 'pillow.js', content_length: 32363, download_percentage: 0 },
    {
        name: 'pyodide.asm.data',
        content_length: 5316326,
        download_percentage: 0,
    },
    { name: 'pyodide.asm.js', content_length: 1996271, download_percentage: 0 },
    {
        name: 'pyodide.asm.wasm',
        content_length: 9495249,
        download_percentage: 0,
    },
    { name: 'pyodide_py.tar', content_length: 102400, download_percentage: 0 },
    { name: 'pyparsing.data', content_length: 212454, download_percentage: 0 },
    { name: 'pyparsing.js', content_length: 8728, download_percentage: 0 },
    {
        name: 'python-dateutil.data',
        content_length: 330202,
        download_percentage: 0,
    },
    {
        name: 'python-dateutil.js',
        content_length: 10297,
        download_percentage: 0,
    },
    { name: 'pytz.data', content_length: 628652, download_percentage: 0 },
    { name: 'pytz.js', content_length: 75730, download_percentage: 0 },
    { name: 'scipy.data', content_length: 21305317, download_percentage: 0 },
    { name: 'scipy.js', content_length: 355622, download_percentage: 0 },
    { name: 'six.data', content_length: 22764, download_percentage: 0 },
    { name: 'six.js', content_length: 5101, download_percentage: 0 },
]
